<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <!-- Header -->
      <div class="row">
        <div class="project-header">คู่มือและเอกสารแบบฟอร์มต่าง ๆ ของรายวิชา{{ type_name }}</div>
        <div class="ml-auto">
          <router-link v-if="role === 'STF'" :to="'/document/' + this.$route.params.type_name + '/edit/'">
            <button class="edit-button">แก้ไขคู่มือและเอกสาร</button>
          </router-link>
        </div>
      </div>

      <!-- File list -->
      <div v-if="has_file" class="my-4">
        <div v-for="file in files" :key="file.id" class="row">
          <button v-on:click="download(file)" class="view-button">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="white"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
          </button>
          <p class="my-auto ml-4">{{ file.name }}</p>
        </div>
      </div>

      <!-- File: not found -->
      <div v-else class="my-4">
        <p>ไม่มีคู่มือและเอกสารใด ๆ ของรายวิชา{{ this.type_name }} ภายในระบบ</p>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { documentFileList } from "../mixins/other"

export default {
  name: "DocumentView",
  components: {
    NavBar,
  },
  data() {
    return {
      loading: true,
      type_name: "",
      files: null,
      file_name: null,
      has_file: false,
      role: null,
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    this.files = await this.$store.dispatch("getDocument")
    let doc = documentFileList(this.files, this.$route.params.type_name, this.type_name)
    this.files = doc.files
    this.type_name = doc.name
    this.has_file = this.files.length > 0 ? true : false
    document.title = "คู่มือและเอกสารรายวิชา" +  this.type_name + " | Carbon"
    this.loading = false
  },
  methods: {
    async download(file) {
      file.type = "document"
      await this.$store.dispatch("downloadDocumentFile", file)
    }
  },
}
</script>